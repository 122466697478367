<template>
    <div class="container JoinWeddingDate">
        <page-header
            :title="`예식은 언제인가요?`"
            title-size="3.2rem"
        />

        <div class="ui-border-line ui-mt-4 ui-mb-4" style="height: 10px"></div>

        <div
            :style="{
                color: '#a3a3a3',
                fontSize: '20px',
                marginBottom: '20px',
            }"
        >
            연도
        </div>

        <tag-box>
            <li
                v-for="(row, key) in yearArr"
                :key="key"
                @click="() => { formData.year = row }"
                :class="{ on: formData.year === row }"
                >
                {{row}}년
            </li>
        </tag-box>

        <div
            :style="{
                color: '#a3a3a3',
                fontSize: '20px',
                marginTop: '40px',
                marginBottom: '20px',
            }"
        >
            월
        </div>

        <tag-box>
            <li
                v-for="(row, key) in monthArr"
                :key="key"
                @click="() => { formData.month = row }"
                :class="{ on: formData.month === row }"
                >
                {{row}}월
            </li>
        </tag-box>

        <footer-box
            submitText="다음"
            single
            :submitCb="() => nextStep()"
            :submitDisabled="!validate"
        >

        </footer-box>
    </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'
import Modal from '@/components/common/Modal'
import Icon from '@/components/common/Icon'
import TagBox from '@/components/common/TagBox'

const currentYear = new Date().getFullYear()
const yearArr = Array(3).fill().map((v, k) => currentYear + k)
const monthArr = Array(12).fill().map((v, k) => 1 + k)

export default {
    name: 'JoinWeddingDate',
    components: {
        PageHeader,
        FooterBox,
        Modal,
        Icon,
        TagBox,
    },
    data() {
        return {
            yearArr,
            monthArr,
            formData: {
                year: null,
                month: null,
            },
        }
    },
    computed: {
        eventDate() {
            if (this.formData.year && this.formData.month) {
                return `${this.formData.year}-${String(this.formData.month).padStart(2, 0)}`
            }
            return null
        },
        validate() {
            return !!this.eventDate
        },
    },
    beforeRouteEnter (to, from, next) {
        next(Vue => {
            if (!sessionStorage.getItem('joinData')) return
            try {
                const joinData = JSON.parse(sessionStorage.getItem('joinData'))
                if (!joinData.eventDate) return

                const keys = ['year', 'month']
                const arr: [] = joinData.eventDate.split('-')

                arr.forEach((row, key) => {
                    Vue.formData[keys[key]] = parseInt(row, 10)
                })
            } catch (error) {
                //
            }
        })
    },
    methods: {
        nextStep() {
            try {
                let joinData = JSON.parse(sessionStorage.getItem('joinData'))

                if (this.eventDate) {
                    joinData.eventDate = this.eventDate
                }

                sessionStorage.setItem('joinData', JSON.stringify(joinData))
            } catch (error) {
                //
            }

            this.$router.push('/join/input')
        },
    },
}
</script>

<style>

</style>