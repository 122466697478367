var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container JoinWeddingDate" },
    [
      _c("page-header", {
        attrs: { title: "예식은 언제인가요?", "title-size": "3.2rem" }
      }),
      _c("div", {
        staticClass: "ui-border-line ui-mt-4 ui-mb-4",
        staticStyle: { height: "10px" }
      }),
      _c(
        "div",
        {
          style: {
            color: "#a3a3a3",
            fontSize: "20px",
            marginBottom: "20px"
          }
        },
        [_vm._v(" 연도 ")]
      ),
      _c(
        "tag-box",
        _vm._l(_vm.yearArr, function(row, key) {
          return _c(
            "li",
            {
              key: key,
              class: { on: _vm.formData.year === row },
              on: {
                click: function() {
                  _vm.formData.year = row
                }
              }
            },
            [_vm._v(" " + _vm._s(row) + "년 ")]
          )
        }),
        0
      ),
      _c(
        "div",
        {
          style: {
            color: "#a3a3a3",
            fontSize: "20px",
            marginTop: "40px",
            marginBottom: "20px"
          }
        },
        [_vm._v(" 월 ")]
      ),
      _c(
        "tag-box",
        _vm._l(_vm.monthArr, function(row, key) {
          return _c(
            "li",
            {
              key: key,
              class: { on: _vm.formData.month === row },
              on: {
                click: function() {
                  _vm.formData.month = row
                }
              }
            },
            [_vm._v(" " + _vm._s(row) + "월 ")]
          )
        }),
        0
      ),
      _c("footer-box", {
        attrs: {
          submitText: "다음",
          single: "",
          submitCb: function() {
            return _vm.nextStep()
          },
          submitDisabled: !_vm.validate
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }